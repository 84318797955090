<template>
  <div class="page blog-post" v-if="post">
    <div class="wrapper">
      <div class="post-categories">
        <div class="post-category" v-for="category in post.categories" :key="category">{{ category }}</div>
        <div class="post-views" :no-categories="!post.categories.length">
          <i class="ic ic-view small"></i>
          {{ post.views }}
          Views
        </div>
      </div>
      <h1>{{ post.title }}</h1>
      <h2>{{ post.subtitle }}</h2>
      <div class="post-info">
        <div class="post-meta">
          <div class="post-author" v-if="post.author">By <span>{{ post.author.name }}</span></div>
          <div class="post-date">Posted: {{ post.created_at }}</div>
        </div>
        <div class="post-share">
          <span>Share</span>
          <button class="icon-button l" @click="shareOpen = true">
            <i class="ic small ic-share"></i>
          </button>
        </div>
      </div>
      <div class="post-cover" v-if="post.picture">
        <img :src="post.picture.url" :alt="post.picture.caption" class="post-cover-image">
        <figcaption>{{ post.picture.caption }}</figcaption>
      </div>
      <div class="post-author-bio" v-if="post.author" v-html="post.author.bio"></div>
      <div class="post-content" v-html="post.content"></div>
      <div class="post-navigation" v-if="previousPost || nextPost">
        <div class="post-nav-item previous">
          <router-link :to="`/news/${previousPost.slug}`" class="post-box" v-if="previousPost">
            <div class="icon-button l">
              <i class="ic ic-chevron-left"></i>
            </div>
            <div class="post-box-content">
              <div class="post-box-title">{{ previousPost.title }}</div>
              <div class="post-box-label">Previous</div>
            </div>
          </router-link>
        </div>
        <div class="post-nav-item next">
          <router-link :to="`/news/${nextPost.slug}`" class="post-box" v-if="nextPost">
            <div class="post-box-content">
              <div class="post-box-title">{{ nextPost.title }}</div>
              <div class="post-box-label">Next</div>
            </div>
            <div class="icon-button l">
              <i class="ic ic-chevron-right"></i>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="shareOpen" @click="shareOpen = false">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Share it now!</div>
        <div class="dialog-content">
          Copy and share the link below
        </div>
        <div class="share-box">
          <div class="share-link">{{ postUrl }}</div>
          <button class="btn" @click="copyLink">Copy</button>
        </div>
        <div class="dialog-message" :style="{ opacity: +linkCopied }">You've copied the link in your clipboard, all good!</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import {
  collection, onSnapshot, query, where, getDocs, orderBy, limit,
} from 'firebase/firestore';
import { db } from '../api';
import { client } from '../api/api';

export default {
  name: 'BlogPost',
  data() {
    return {
      shareOpen: false,
      linkCopied: false,
      previousPost: null,
      nextPost: null,
      post: null,
    };
  },
  computed: {
    ...mapState(['user']),
    postUrl() {
      return window.location.href;
    },
  },
  watch: {
    $route() {
      this.getPost();
    },
  },
  methods: {
    copyLink() {
      if (this.linkCopied) {
        return;
      }
      this.linkCopied = true;
      navigator.clipboard.writeText(this.postUrl);
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    getPost() {
      this.post = null;
      this.postRef = onSnapshot(query(collection(db, 'posts'), where('slug', '==', this.$route.params.id)), async (docs) => {
        if (docs.empty) {
          return this.$router.push('/news');
        }
        const data = docs.docs[0].data();
        const date = data.created_at.toDate();
        const hasChanged = this.post?.id !== data.id;
        this.post = {
          views: 0,
          ...data,
          content: data.content.replace(/<p><br><\/p>/gi, ''),
          created_at: moment(date).format('MMM DD, YYYY - H:mm a'),
        };
        if (hasChanged) {
          if (data.active) {
            client.post(`/posts/${data.id}/view`);
          }
          const previous = await getDocs(query(collection(db, 'posts'), where('active', '==', true), where('created_at', '<', date), orderBy('created_at', 'desc'), limit(1)));
          const next = await getDocs(query(collection(db, 'posts'), where('active', '==', true), where('created_at', '>', date), orderBy('created_at', 'asc'), limit(1)));
          this.previousPost = previous.docs[0]?.data() || null;
          this.nextPost = next.docs[0]?.data() || null;
        }
      });
    },
  },
  mounted() {
    this.getPost();
  },
  beforeDestroy() {
    if (this.postRef) {
      this.postRef();
    }
  },
};
</script>

<style scoped>
.page {
  padding: 40px 0;
}

.wrapper {
  width: 1056px;
}

.post-categories {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}

.post-category {
  background-color: #fff;
  color: #F36;
  padding: 6px 12px;
  border-radius: 28px;
  font-weight: bold;
  font-size: 14px;
}

.post-views {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: 12px;
  color: rgba(255, 255, 255, 0.69);
}

.post-views[no-categories] {
  margin-left: 0;
}

h1 {
  font-size: 40px;
  margin: 24px 0 12px;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

.post-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
}

.post-meta {
  display: flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.69);
}

.post-cover {
  margin-bottom: 32px;
}

.post-share {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
}

.post-share .icon-button {
  background-color: #393652;
}

.post-author {
  border-right: 1px solid;
  padding-right: 8px;
}

.post-author span {
  color: #F36;
}

.post-cover-image {
  display: block;
  height: 560px;
  width: calc(100% + 36px);
  margin-left: -18px;
  object-fit: cover;
  margin-bottom: 12px;
}

.post-navigation {
  display: flex;
  justify-content: space-between;
  padding-top: 24px;
  margin-top: 88px;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item {
  width: 50%;
}

.post-nav-item.previous {
  padding-right: 48px;
  border-right: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-nav-item.next {
  padding-left: 48px;
}

.post-box {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 24px 32px;
  background: #1F1D2C;
  gap: 24px;
  border-radius: 16px;
  color: rgba(255, 255, 255, 0.69);
}

.post-box .post-box-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #fff;
}

.post-box .icon-button {
  background: #393652;
}

.post-nav-item.previous .post-box .icon-button .ic {
  margin-left: -2px;
}

.post-nav-item.next .post-box .icon-button .ic {
  margin-right: -2px;
}

.post-box .post-box-content {
  flex-grow: 1;
}

.post-content {
  font-size: 18px;
  line-height: 1.6667;
}

.post-content >>> h1,
.post-content >>> h2,
.post-content >>> h3,
.post-content >>> h4,
.post-content >>> h5,
.post-content >>> h6,
.post-content >>> p,
.post-content >>> img,
.post-content >>> figcaption,
.post-content >>> blockquote {
  display: block;
  margin-top: 0;
  margin-bottom: 32px;
  color: #fff !important;
}

.post-content >>> p:empty {
  display: none;
}

.post-content >>> h1 {
  font-size: 32px;
}

.post-content >>> h2 {
  font-size: 24px;
}

.post-content >>> h3 {
  font-size: 20px;
}

.post-content >>> h4 {
  font-size: 18px;
}

.post-content >>> h5 {
  font-size: 16px;
}

.post-content >>> h6 {
  font-size: 14px;
}

.post-content >>> img {
  width: 100%;
}

.post-content >>> ul {
  margin: 0 0 32px;
}

.post-cover figcaption,
.post-content >>> figcaption {
  display: block;
  color: rgba(255, 255, 255, 0.69) !important;
  font-size: 14px;
}

.post-content >>> figcaption {
  margin-top: -24px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio,
.post-content >>> blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: 12px 0;
  font-size: 16px;
  line-height: normal;
  font-style: italic;
  border-top: 1px dashed rgba(255, 255, 255, 0.46);
  border-bottom: 1px dashed rgba(255, 255, 255, 0.46);
}

.post-author-bio {
  margin-bottom: 32px;
  color: #fff !important;
}

.post-author-bio >>> * {
  color: #FFF !important;
}

.post-content >>> a,
.post-author-bio >>> a {
  color: #F36 !important;
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .post-cover-image {
    height: 400px;
  }
}

@media screen and (max-width: 880px) {
  .post-nav-item.previous {
    padding-right: 24px;
  }
  .post-nav-item.next {
    padding-left: 24px;
  }
  .post-box {
    padding: 16px;
    gap: 16px;
  }
  .post-box .post-box-title {
    font-size: 18px;
  }
  .post-cover-image {
    margin-left: -24px;
    width: calc(100% + 48px);
  }
}

@media screen and (max-width: 580px) {
  h1 {
    font-size: 32px;
  }
  .post-meta {
    flex-direction: column;
    align-items: flex-start;
  }
  .post-author {
    border: none;
  }
  .post-nav-item.previous {
    padding-right: 12px;
  }
  .post-nav-item.next {
    padding-left: 12px;
  }
  .post-box .icon-button {
    display: none;
  }
  .post-content {
    font-size: 16px;
  }
  .post-box .post-box-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .post-cover-image {
    height: 100vw;
  }
}
</style>
